@import url("https://use.typekit.net/eqd7gci.css");
@import url("https://use.typekit.net/jxt2zbi.css");


@font-face {
    font-family: 'Trip';
    font-style: normal;
    src:
        url(../fonts/Triptych-Roman.woff2) format("woff2"),
        url(../fonts/Triptych-Roman.woff) format("woff");
}

@font-face {
    font-family: 'Trip';
    font-style: italic;
    src:
        url(../fonts/Triptych-Italick.woff2) format("woff2"),
        url(../fonts/Triptych-Italick.woff) format("woff");
}