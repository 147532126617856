@import "tailwindcss/base";
@import "./swiper.css";
@import "./typography.css";
@import "./defaults.css";
@import "tailwindcss/components";


@import "./layout.css";
/* @import "./menu.css"; */
/* @import "./plyr2.css"; */
/* @import "./menu.css"; */



@import "tailwindcss/utilities";