
body {
    @apply antialiased;
}

a {
    @apply underline duration-300;
}

a:hover {
    /* @apply opacity-50; */
}

.dotList li { display: inline; white-space: pre; @apply text-sm}
.dotList li:before { content: "  \00b7  "; }
.dotList li:first-child:before { content: ""; }


.dwArrow {
    
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%234c71ae'%2F%3E%3C%2Fsvg%3E") !important;
}


.moveMe {
    @apply transform transition-transform duration-300 origin-bottom-left cursor-pointer;
}

.moveTile {
    @apply rotate-[-2deg];
}

.quote p::before {
    content: '\201C';
    margin-left: -17px;
}

.quote p::after {
    content: '\201D';
}

.home2Grid {
    @apply px-40 grid grid-cols-12 gap-5 w-full ;
}

.bubbleOuter {
    @apply fixed top-0 left-0 pt-5 transform transition-transform duration-500 z-50;
}

.bubbleInner1 {
    @apply col-span-6 col-start-4 ;
}

.bubbleInner2 {
    @apply m-auto px-4 pt-[0.3rem] pb-[0.4rem] rounded-full w-max bg-offWhite2/90 no-underline;
    /* box-shadow: 0 0 4px 4px rgba(241,241,241,0.7); */
}

.bubbleBlock {
    @apply pb-32;
}

.clipHouse {
    -webkit-clip-path: url("#houseClip");
    clip-path: url("#houseClip");
}

.openGallery {
    @apply cursor-zoom-in;
}


#mainNav {
    @apply border-2 border-red-400;
}

/* purgecss start ignore */


.transUp {
    @apply: translate-y-28;
}
   
.transDown {
    @apply: translate-y-[50vh];
}



.twic-blur {
    /* opacity: 0; */
    /* will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out; */
  }

  .twic-done {
    opacity: 1;
  }

  .twic-done ~ .swipePic {
    @apply -translate-y-full; 
  }

/* purgecss end ignore */